import React, { useEffect, useState } from "react";
import "./style.scss";
import BaseTextField from "../../general/components/Forms/BaseTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import AppResource from "../../general/constants/AppResource";
import QRCode from "react-qr-code";
import Utils from "../../general/utils/Utils";
function Home() {
  const [dataRes, setDataRes] = useState();
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState();

  const formik = useFormik({
    initialValues: {
      mssv: null,
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let res = await fetch(
          `https://pay.mdcsoftware.com.vn/payment/qr?MSSV=${values.mssv}`
        );
        const data = await res.json();
        setDataRes(data);
        setTransaction();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    validationSchema: Yup.object({
      mssv: Yup.string().trim().required("Vui lòng nhập mssv !").nullable(),
    }),
  });

  useEffect(() => {
    let flag = false;
    let myInterval = setInterval(async () => {
      if (flag) {
        return;
      }
      flag = true;
      if (
        dataRes?.orderId &&
        (!transaction || transaction?.status === "PENDING")
      ) {
        await checkStatus();
      }
      flag = false;
    }, 2000);
    return () => {
      clearInterval(myInterval);
    };
  }, [dataRes]);
  async function checkStatus() {
    try {
      const response = await fetch(
        `https://pay.mdcsoftware.com.vn/payment/status?orderId=${dataRes?.orderId}`
      );

      const data = await response.json();
      setTransaction(data);
      return data;
    } catch (error) {
      console.error("Đã xảy ra lỗi:", error);
      return null;
    }
  }
  const renderStatus = () => {
    switch (transaction?.status) {
      case "COMPLETED":
        return <p className="text-success">Thanh toán thành công</p>;
        break;
      case "FAILED":
        return <p className="text-danger">Thanh toán thất bại</p>;
        break;

      default:
        return <p className="text-warning">Đang chờ thanh toán</p>;
        break;
    }
  };
  return (
    <div className="container mt-5 pb-5 ">
      <div className="  home-container border p-3 p-md-5 shadow ">
        <h1 className="text-center pb-2 text-title pt-2">
          DEMO THANH TOÁN QUA MÃ QR
        </h1>
        <div className="row align-items-center gx-5 gy-3">
          <div className="col-12 col-md-6 ">
            <div className="d-flex align-items-center justify-content-center">
              <div className="me-4 flex-shrink-0" style={{ fontWeight: "600" }}>
                {" "}
                Nhập MSSV
              </div>
              {/* <input
                  placeholder="Nhập mssv..."
                  className="py-2 px-2 flex-grow-1"
                          ></input> */}
              <div className="flex-grow-1">
                <BaseTextField
                  //placeholder='Nhập họ tên'
                  //   label="Họ tên"
                  name="mssv"
                  require={true}
                  fieldHelper={formik.getFieldHelpers("mssv")}
                  fieldMeta={formik.getFieldMeta("mssv")}
                  fieldProps={formik.getFieldProps("mssv")}
                />
              </div>
            </div>
          </div>
          {/* thành công */}
          {dataRes?.result === "success" && (
            <div className="col-12 col-md-6 text-center ">
              <p style={{ fontWeight: "700" }}>
                Vui lòng quét mã QR để tiến hành thanh toán
              </p>

              {/* <img src={dataRes?.qrLink} alt="qr" className="img_qr" /> */}
              <QRCode value={dataRes?.qrCode} size="250" />
            </div>
          )}
          {/* mặc định */}
          {!dataRes && (
            <div className="col-12 col-md-6 text-center ">
              <p style={{ fontWeight: "700" }}>
                Vui lòng quét mã QR để tiến hành thanh toán
              </p>
              <img src={AppResource.images.imgDefault} className="img_qr" />
            </div>
          )}

          {/*  lỗi */}
          {dataRes?.reason && (
            <div className="col-12 col-md-6 text-center ">
              <p style={{ fontWeight: "700" }}>
                Vui lòng quét mã QR để tiến hành thanh toán
              </p>
              <img src={AppResource.images.imgDefault} className="img_qr" />
              <p className="text-danger mt-2">{dataRes?.reason}</p>
            </div>
          )}
        </div>
        <div className="row align-items-center justify-content-center mt-3 gy-3">
          <div className="col-12 col-md-6 text-center">
            <div
              className="btn btn-primary py-3 px-5"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Thanh toán {loading && <Spinner animation="border" size="sm" />}
            </div>
          </div>
          {dataRes?.result === "success" ? (
            <div className="col-12 col-md-6 d-flex justify-content-center ">
              <div className="text-info-content">
                <div className="d-flex">
                  <div
                    // className="pe-2"
                    style={{ fontWeight: "600", width: "120px" }}
                  >
                    Mã giao dịch:{" "}
                  </div>
                  <p>{dataRes?.orderId}</p>
                </div>
                <div className="d-flex">
                  <div
                    className="pe-2"
                    style={{ fontWeight: "600", width: "120px" }}
                  >
                    Số tiền:{" "}
                  </div>
                  <p>{Utils.parseCurrency(dataRes?.amount)}</p>
                </div>
                <div className="d-flex ">
                  <div
                    className="pe-4"
                    style={{ fontWeight: "600", width: "120px" }}
                  >
                    Trạng thái:
                  </div>
                  {renderStatus()}
                </div>
              </div>
            </div>
          ) : (
            <div className="col-12 col-md-6 "></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
