// App resources: icons, images, fonts...
const AppResource = {
  // icons
  icons: {},

  // images
  images: {
    // img_registered_ministry_trade: require("assets/images/img_registered_ministry_trade.png"),
    imgDefault: require("../../assets/images/imgDefault.jpg"),
  },

  // colors
  colors: {
    primary: "#E31D93",
    secondary: "#19D3C5",
    secondary02: "#0071CE",
    feature: "#E31D93",
    darkGrey: "#262224",
    grey: "#5A5C6F",
    grey50: "#ACACBB",
    lightGreyStroke: "#E0E2ED",
    lineIndicator: "#E8EFF4",
    lightGrey50: "#F1F5F8",
    white: "#ffffff",
    featureBlue: "#0066C7",
    featureYellow: "#FF9427",
    featureGreen: "#18CDBD",
    red: "#CD1F13",
    textSecondary: "#6B686A",
    textPrimary: "#262224",
    placeholder: "#A8A8A8",
    black: "#000",
    error01: "#9E0038",
    success: "#0071CE",
    neutral: "#EBEBEB",
  },
};

export default AppResource;
